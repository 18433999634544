import React, { Component } from 'react';
import './company.css'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import Moment from 'moment';
import Modal from 'react-modal';

var config = require('../../config/config');
const returnJsonWithAuth = require('../shared/authHandler');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Company extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companyId: props.match.params["companyId"],
            companyName: props.location?.state?.Name,
            orders: [],
            testOrders: [],
            stepSpecific: {},
            stepSpecificDialogOpen: false,
            selectedStepSpecificData: [],
            selectedStepSpecificOrder: null,
            orderSearchIndex: 0,
            orderSearchMatches: [],
            orderSearchStatus: null,
            orderSearchTerm: '',
            fileNumberSearchDisplay: '',
        };

        if (!this.state.companyName) {
            this.getCompanyName()
        }

        this.loadCompanyData = this.loadCompanyData.bind(this);
        this.loadCompanyTestData = this.loadCompanyTestData.bind(this);
        this.copyTestOrderData = this.copyTestOrderData.bind(this);
        this.searchFileNumberOrderId = this.searchFileNumberOrderId.bind(this);
    }

    componentDidMount() {
        this.loadCompanyData();
        this.loadCompanyTestData();
        this.loadStepSpecificCompanyData();
        Modal.setAppElement('body');
    }

    toOrder = (companyName, companyId, orderId) => {
        this.props.history.push({
            pathname: '/company/' + companyId + '/order/' + orderId,
            state: companyName
        });
    }

    toStepSpecificOrder = (companyName, companyId, orderId, stepId) => {
        this.props.history.push({
            pathname: '/company/' + companyId + '/order/' + orderId + "/stepSpecific/" + stepId,
            state: companyName
        });
    }

    async startOrderSearch() {
        this.setState({
            orderSearchIndex: 0,
            orderSearchMatches: [],
         }, () => this.doOrderSearch())
    }

    resumeOrderSearch() {
        this.doOrderSearch()
    }

    async doOrderSearch() {
        let { orderSearchIndex, orders, orderSearchTerm } = this.state;
        console.log('doOrderSearch()', orderSearchIndex, orderSearchTerm);

        this.setState({
            orderSearchStatus: 'searching',
        });
        
        while(orderSearchIndex < orders.length) {
            const { Key } = orders[orderSearchIndex];
            const order = await this.getOrder(this.state.companyId, Key);
            console.log('order:', order)
            const body = JSON.stringify(order.OrderData)
            orderSearchIndex += 1;
            this.setState({ orderSearchIndex });

            if ( body?.toLowerCase()?.includes(orderSearchTerm.toLowerCase())) {
                const order = JSON.parse(body)
                console.log('got match!', Key, order);
                this.setState({
                    orderSearchMatches: [...this.state.orderSearchMatches, Key],
                    orderSearchStatus: 'paused',
                })
                return
            }
        }
    }

    async getOrder(companyId, orderId) {
        const url = config.diagnosticApiHost + "mappingDiagnostic/" + companyId + "?orderId=" + orderId
        return fetch(url, config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, () => this.getOrder(companyId, orderId)))
    }

    getCompanyName() {
        fetch(config.diagnosticApiHost + "company/" + this.state.companyId, config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, this.getCompanyName))
            .then(
                (result) => {
                    if (result) {
                        this.setState({
                            companyName: result.Name
                        });
                    }
                },
                (error) => {
                    debugger;
                }
            );
    }

    loadCompanyData() {
        var path = `${config.diagnosticApiHost}orders/${this.state.companyId}`
        if (this.props.location.search) {
            path = `${path}${this.props.location.search}`
        }
        fetch(path, config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, this.loadCompanyData))
            .then(
                (result) => {
                    if (result) {
                        this.setState({
                            orders: result
                        });
                    }
                },
                (error) => {
                    debugger;
                }
            )
    }

    loadStepSpecificCompanyData() {
        fetch(config.diagnosticApiHost + "company/" + this.state.companyId + "/stepOrders", config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, this.loadCompanyData))
            .then(
                (result) => {
                    if (result) {
                        this.setState({
                            stepSpecific: result
                        });
                    }
                },
                (error) => {
                    debugger;
                }
            )
    }

    loadCompanyTestData() {
        fetch(config.diagnosticApiHost + "testOrders/" + this.state.companyId, config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, this.loadCompanyData))
            .then(
                (result) => {
                    if (result) {
                        this.setState({
                            testOrders: result
                        });
                    }
                },
                (error) => {
                    debugger;
                }
            )
    }

    copyTestOrderData(companyId, orderId, stepId) {
        fetch(config.diagnosticApiHost + "copyTestOrder", config.apiOptions("POST", JSON.stringify({
            companyId: companyId,
            orderId: orderId,
            stepId: stepId
        })))
            .then(() => {
                this.setState({ stepSpecificDialogOpen: false });
                this.loadCompanyData();
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                })
    }

    fileNumberChanged = (event) => {
        this.setState({
            fileNumber: event.target.value
        });
    }

    orderIdChanged = (event) => {
        this.setState({
            orderId: event.target.value
        });
    }

    async searchFileNumberOrderId() {
        this.setState({ fileNumberSearchDisplay: 'Converting…' });
        const { OrderId } = await this.doSearchFileNumberOrderId("fileNumber="+this.state.fileNumber)
        if (OrderId && OrderId !== 'Not Found') {
            this.setState({ fileNumberSearchDisplay: `OrderId: ${OrderId}` });
            return
        }
        const { FileNumber } = await this.doSearchFileNumberOrderId("orderId="+this.state.fileNumber)
        if (FileNumber && FileNumber !== 'Not Found') {
            this.setState({ fileNumberSearchDisplay: `FileNumber: ${FileNumber}` });
            return
        }
        this.setState({ fileNumberSearchDisplay: `Not Found` });
    }

    doSearchFileNumberOrderId(searchString) {
        return fetch(config.diagnosticApiHost + "company/" + this.state.companyId + "/convertFileNumberOrderId?" + searchString, config.apiOptions("GET"))
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
            })
    }

    showSpecificOrder(orderId) {
        if (this.state.stepSpecific[orderId]) {
            this.setState({
                stepSpecificDialogOpen: true,
                selectedStepSpecificData: this.state.stepSpecific[orderId],
                selectedStepSpecificOrder: orderId
            });
        }
    }

    render() {
        return (
            <div>
                <div className="flex">
                    <a href="/" style={{ margin: '5px 20px 5px 5px'}}>Home</a>
                    
                    <div className="flex-column">
                        <div className="flex">
                            {/* <label style={{ width: '80px' }}>FileNumber / Order Id</label> */}
                            <input value={this.state.fileNumber} onChange={this.fileNumberChanged} placeholder="FileNumber / OrderId"></input>
                            <button onClick={() => this.searchFileNumberOrderId()}>Convert</button>
                            <div className="margin-h">
                                {this.state.fileNumberSearchDisplay}
                            </div>
                        </div>
                    </div>


                    <div style={{ marginLeft: '20px' }} className="flex">
                        <input value={this.state.orderSearchTerm} onChange={e => this.setState({ orderSearchTerm: e.target.value })}></input>
                            
                        <button onClick={() => this.startOrderSearch()}>Search Orders</button>

                        { this.state.orderSearchStatus ? 
                            <div className="margin-h">
                                <div className="margin-h">
                                    <span>
                                        {this.state.orderSearchStatus === 'searching' ? 'Searching' : 'Searched' }&nbsp;
                                        {this.state.orderSearchIndex} of {this.state.orders.length} orders.  Matches:
                                    </span>
                                    { this.state.orderSearchMatches.map(Key => 
                                        <div key={Key}>
                                            <Link to={`/company/${this.state.companyId}/order/${Key}`} state={this.state.companyName}>
                                                {Key}
                                            </Link>
                                        </div>
                                    ) }
                                    { this.state.orderSearchStatus === 'paused'
                                        ?  <button onClick={() => this.resumeOrderSearch()} style={{padding: '1px'}}>Continue</button>
                                        : <></>
                                    }

                                </div>
                            </div>
                        : <></>
                        }                        
                    </div>
                </div>
                <hr />
                <div>
                    <span>Company Name: {this.state.companyName}</span><br />
                    <span>Company Id: {this.state.companyId}</span><br />
                    <br />
                    <span>Orders:</span><br />
                    <table>
                        <thead>
                            <tr>
                                <td>Order Id</td>
                                <td>Modified Date</td>
                                <td>Step Specific Orders</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orders.map(order => (
                                <tr key={order.Key} >
                                    <td>
                                        <Link to={`/company/${this.state.companyId}/order/${order.Key}`}>
                                            {order.Key}
                                        </Link>
                                    </td>
                                    <td>{Moment(order.ModifiedDateTime).format('YYYY-MM-DD HH:mm')}</td>
                                    <td onClick={() => this.showSpecificOrder(order.Key)} className="clickable">{this.state.stepSpecific[order.Key] ?
                                        this.state.stepSpecific[order.Key].length
                                        : 0}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <span>Test Orders:</span><br />
                    <table>
                        <tbody>
                            {this.state.testOrders.map(order => (
                                <tr key={order.Key}><td>{order.Key}</td><td><button onClick={() => this.copyTestOrderData(this.state.companyId, order.Key)} className="clickable">Copy To Orders</button></td></tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal
                    isOpen={this.state.stepSpecificDialogOpen}
                    style={customStyles}
                    contentLabel="Step Specific">
                    <div>OrderId: {this.state.selectedStepSpecificOrder}</div>
                    <table>
                        <thead>
                            <tr>
                                <td>Step Id</td>
                                <td>Modified Date</td>
                                <td>Copy</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.selectedStepSpecificData.map(data => (
                                <tr key={data.StepId}>
                                    <td onClick={() => this.toStepSpecificOrder(this.state.Name, this.state.companyId, data.OrderId, data.StepId)} className="clickable">{data.StepId}</td>
                                    <td>{Moment(data.ModifiedDateTime).format('YYYY-MM-DD HH:mm')}</td>
                                    <td><button onClick={() => this.copyTestOrderData(this.state.companyId, data.OrderId, data.StepId)}>Copy To Orders</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <br />
                    <button onClick={() => this.setState({ stepSpecificDialogOpen: false })}>Close</button>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Company);
import React from "react";
import ReactDOM from "react-dom";
import Home from "./app/home/home";
import Company from "./app/company/company";
import Order from "./app/order/order";
import Deploy from "./app/deploy/deploy";
import StepSpecificOrder from "./app/order/stepSpecificOrder";
import OrderEdit from "./app/orderEdit/orderEdit";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import SPFormulaBuilder from "./app/build-tools/spFormulaBuilder/formulabuilder";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/deploy" component={Deploy} />
      <Route
        path="/company/:companyId/order/:orderId/stepSpecific/:stepId"
        component={StepSpecificOrder}
      />
      <Route path="/company/:companyId/order/:orderId" component={Order} />
      <Route
        path="/company/:companyId/orderEdit/:orderId"
        component={OrderEdit}
      />
      <Route path="/company/:companyId" component={Company} />
      <Route path="/company" render={(props) => <Company {...props} />} />
      <Route path="/build-tools/softpro-formula" component={SPFormulaBuilder} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

function About() {
  return (
    <div>
      <h2>This page does not have an about!</h2>
    </div>
  );
}

serviceWorker.unregister();

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import './deploy.css';

var config = require('../../config/config');
const returnJsonWithAuth = require('../shared/authHandler');



class Deploy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pipelines: [],
      branches: [],
      selectedBranch: null,
      selectedPipeline: null
    };
    this.loadPipelines = this.loadPipelines.bind(this);
    this.loadPipelineCurrentBranch = this.loadPipelineCurrentBranch.bind(this);
    this.loadRepoBranches = this.loadRepoBranches.bind(this);
    this.deployProject = this.deployProject.bind(this);
    this.startProject = this.startProject.bind(this);
    this.onPipelineChange = this.onPipelineChange.bind(this);
    this.onBranchChange = this.onBranchChange.bind(this);
  }

  componentDidMount() {
    this.loadPipelines();
    //this.loadPipelineCurrentBranch();
    //this.loadRepoBranches();
    //this.deployProject();
  }

  loadPipelines() {
    fetch(config.diagnosticApiHost + "deploy/pipelines", config.apiOptions("GET"))
      .then(res => returnJsonWithAuth(res, this.state, this.loadPipelines))
      .then(
        (result) => {
          if (result) {
            var defaultPipelineName = result[0]?.Name;
            this.setState({
              pipelines: result,
              selectedPipeline: defaultPipelineName
            });
            this.onPipelineChange({
              target: {
                value: defaultPipelineName
              }
            });
          }
        },
        (error) => {
          debugger;
        }
      )
  }

  loadPipelineCurrentBranch(pipelineName) {
    fetch(config.diagnosticApiHost + `deploy/pipeline/${pipelineName}/currentBranch`, config.apiOptions("GET"))
      .then(res => returnJsonWithAuth(res, this.state, this.loadPipelineCurrentBranch))
      .then(
        (result) => {
          if (result) {
            this.setState({
              currentBranch: result.branchName
            });
          }
        },
        (error) => {
          debugger;
        }
      )
  }

  loadRepoBranches(projectName) {
    fetch(config.diagnosticApiHost + `deploy/github/${projectName}/branches`, config.apiOptions("GET"))
      .then(res => returnJsonWithAuth(res, this.state, this.loadRepoBranches))
      .then(
        (result) => {
          if (result) {
            this.setState({
              branches: result,
              selectedBranch: result[0]
            });
            this.onBranchChange({
              target: {
                value: result[0]
              }
            });
          }
        },
        (error) => {
          debugger;
        }
      )
  }

  deployProject(projectName, branchName) {
    fetch(config.diagnosticApiHost + `deploy/project/${projectName}`, config.apiOptions("POST", JSON.stringify({
      branchName: branchName
    })))
      .then(res => returnJsonWithAuth(res, this.state, this.loadPipelineCurrentBranch))
      .then((result) => {})
      .catch((err) => {
        alert(err);
      })
  }

  startProject() {
    this.deployProject(this.state.selectedPipeline, this.state.selectedBranch);
  }

  onPipelineChange(event) {
    var pipelineName = event.target.value;
    this.setState({
      selectedPipeline: pipelineName,
      selectedBranch: null,
      currentBranch: null,
      branches: []
    });
    this.loadPipelineCurrentBranch(pipelineName);
    this.loadRepoBranches(pipelineName);
  }

  onBranchChange(event) {
    var branchName = event.target.value;
    this.setState({
      selectedBranch: branchName
    });
  }

  render() {
    return (
      <div className="Deploy">
        <div>
          <select onChange={this.onPipelineChange}>
            {this.state.pipelines.map((pipeline) => { return <option key={pipeline.Name} value={pipeline.Name}>{pipeline.Name}</option> })}
          </select>
        </div>
        <div>Current Branch: {this.state.currentBranch}</div>
        <div>
          <select onChange={this.onBranchChange}>
            {this.state.branches.map((branch) => { return <option key={branch}>{branch}</option> })}
          </select>
        </div>
        <button onClick={this.startProject}>Submit</button>
      </div>
    );
  }
}

export default withRouter(Deploy);
import React, { Component } from 'react';
import JSONTree from 'react-json-tree';
import copy from 'copy-text-to-clipboard';
import { jsonTreeConfig } from '../shared/jsonTreeConfig'
import "./order.css";

import DocumentRegex from "../shared/documentRegex";

var config = require('../../config/config');
const returnJsonWithAuth = require('../shared/authHandler');

class StepSpecificOrder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mapping: {},
      url: "test",
      linkTitle: "Loading",
      companyId: this.props.match.params["companyId"],
      data: {},
      companyName: props.location.state,
      orderId: this.props.match.params["orderId"],
      stepId: this.props.match.params["stepId"],
      orderExists: true,
      s3LinkTitle: "Steps completed (S3 Bucket)",
      s3Url: config.orderBucketUrl + this.props.match.params["companyId"] + '/' + this.props.match.params["orderId"] + "/"
    };
    if (!this.state.companyName) {
      this.getCompanyName();
    }
    this.getMappedData = this.getMappedData.bind(this);
  }

  componentDidMount() {
    this.getMappedData();
  }

  getCompanyName() {
    fetch(config.diagnosticApiHost + "company/" + this.state.companyId, config.apiOptions("GET"))
      .then(res => returnJsonWithAuth(res, this.state, this.getCompanyName))
      .then(
        (result) => {
          if (result) {
            this.setState({
              companyName: result.Name
            });
          }
        },
        (error) => {
          this.setState({
            url: "",
            linkTitle: "Error",
          });
        }
      );
  }

  getMappedData() {
    fetch(config.diagnosticApiHost + "company/" + this.state.companyId + "/order/" + this.state.orderId + "/stepOrders/" + this.state.stepId, config.apiOptions("GET"))
      .then(res => returnJsonWithAuth(res, this.state, this.getMappedData))
      .then(
        (result) => {
          this.setState({
            data: result
          });
        },
        (error) => {
          debugger;
        }
      );
  }

  getCompanyRoute = (companyId, companyName) => {
    this.props.history.push({
      pathname: '/company/' + companyId,
      state: {
        Id: companyId,
        Name: companyName
      }
    });
  }


  copyToClipboard = (data) => {
    copy(JSON.stringify(data));
  }

  render = () => {
    return (
      <div>
        <a href="/">Home</a><br />
        <div hidden={this.state.orderExists}>Order Does not Exist</div>
        <div hidden={!this.state.orderExists}>
          <div className="link" onClick={() => this.getCompanyRoute(this.state.companyId, this.state.companyName)}>{this.state.companyName}</div>
          <a href={this.state.s3Url} target="_blank" rel="noopener noreferrer">{this.state.s3LinkTitle}</a>
          <hr />
          <span>Original Order Data</span><br />
          <button onClick={() => this.copyToClipboard(this.state.data)}>Copy To Clipboard</button>
          <JSONTree data={this.state.data} {...jsonTreeConfig} />
          <hr />
          <DocumentRegex data={this.state}></DocumentRegex>
        </div>
      </div>
    );
  }
}

export default StepSpecificOrder;
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './home.css';
import CreateCompanyDialog from './createCompanyDialog';
import Modal from 'react-modal';

const queryString = require('query-string');
const config = require('../../config/config');
const returnJsonWithAuth = require('../shared/authHandler');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class Home extends Component {
  constructor(props) {
    super(props);
    var parsedQueryString = queryString.parse(props.location.search);
    this.state = {
      error: null,
      isLoaded: false,
      companies: [],
      isCompanyDialogOpen: false,
      authCode: parsedQueryString.code,
      redirect: parsedQueryString.state
    };

    this.toggleCompanyDialog = this.toggleCompanyDialog.bind(this);
    this.loadCompanies = this.loadCompanies.bind(this);
  }

  componentDidMount() {
    this.loadCompanies();
    Modal.setAppElement('body');
  }

  loadCompanies() {
    fetch(config.diagnosticApiHost + "companies", config.apiOptions("GET"))
      .then(res => returnJsonWithAuth(res, this.state, this.loadCompanies))
      .then(
        (result) => {
          if (result) {
            this.setState({
              isLoaded: true,
              items: result
            });
          }
        },
        (error) => {
          debugger;
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  
  toggleCompanyDialog() {
    this.setState({
      isCompanyDialogOpen: !this.state.isCompanyDialogOpen
    });
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="Home">
          <button onClick={this.toggleCompanyDialog}>Create Company</button>
          <table>
            <thead>
              <tr>
                <td>Company Name</td><td>Company Id</td>
              </tr>
            </thead>
            <tbody>
              {items.map(item => (
                <tr key={item.CompanyId}>
                  <td>
                    <Link to={`/company/${item.CompanyId}`} state={item}>
                        {item.Name}
                    </Link>
                  </td>
                  <td>{item.CompanyId}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
            isOpen={this.state.isCompanyDialogOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.cancel}
            style={customStyles}
            contentLabel="Create Modal"
          >
            <CreateCompanyDialog toggleCompanyDialog={this.toggleCompanyDialog} />
          </Modal>
        </div>
      );
    }
  }
}

export default Home;

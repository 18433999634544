export const jsonTreeConfig = {
	// Don't collapse nodes so you can cmd+f to find them then:
	collectionLimit: 1000000,

	// Show newlines:
	valueRenderer: raw => <span className="pre">{raw}</span>,

	hideRoot: false,
	shouldExpandNode: () => true,
}

export const Scheduling = (Buyer, Seller) => {
  const sched = (side) =>
    side
      ? '"`' +
        side +
        '`:"+"{"+ "`DateTime`:`"+{{Order.CS' +
        side +
        "DateTime" +
        "##" +
        '}}+"`,"+ "`Location`:`"+{{Order.CS' +
        side +
        "Location" +
        "##" +
        '}}+"`,"+ "`UserTimezone`:`"+FormatTime("%TZ", {{Order.CS' +
        side +
        "DateTime" +
        "##" +
        '}})+"`"+ "}"+","+'
      : "";
  return '"`Scheduling`:"+"{"+' + sched(Buyer) + sched(Seller) + '+ "}"+","';
};

const config = require('../../config/config');

function base64_url_encode(data) {
    return btoa(encodeURIComponent(data)).replace(/\//g, '_').replace(/\+/g, '-').replace(/=/, '~');
}

function base64_url_decode(data) {
    return decodeURIComponent(atob(data.replace(/_/g, '/').replace(/-/g, '+').replace(/~/,'=')));
}

let returnJsonWithAuth = (res, state, postAuthCallback) => {
    if (res.status === 401 || res.status === 403) {
        fetch(config.adminAuthApiHost + "token", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: state.authCode,
                redirect: window.location.origin,
                clientId: config.cognitoClientId
            }),
            credentials: "include"
        }).then(result => {
            if (result.status === 401) {
                window.location.href = config.tokenUrl + base64_url_encode(window.location.origin + window.location.pathname);
                return;
            }
            if (state.redirect) {
                const redirectValue = base64_url_decode(state.redirect);
                window.location.href = redirectValue;
                return;
            }
            postAuthCallback();
        }, error => {
            console.log(error);
        });
        return;
    }

    return res.json();
}

module.exports = returnJsonWithAuth;
import React from "react";
// style elements
import "./formulabuilder.css";
import clipboard from "../../../assets/images/clipboard-regular.svg";
// Components to build the final output string
import OrgInfo from "./org-info";
import { buildPeople, peopleSPLocation } from "./build-people";
import { Scheduling } from "./scheduling";
import { CopySuccess, Checkbox, NumSelector } from "./selector-components";
import { buildOtherContacts } from "./build-others";

const SPFormulaBuilder = () => {
  const [copy, setCopy] = React.useState(false);
  const [master, setMaster] = React.useState({
    People: {
      Closer: true,
      Processor: true,
      TitleOfficer: false,
      HeldBy: false,
      OrderReceivedBy: false,
      OrderCompletedBy: false,
      OrderReservedBy: false,
      EscrowOpenedBy: false,
      TitleOpenedBy: false,
    },
    OrgInfo_Buyer: 0,
    OrgInfo_Seller: 0,
    EarnestMoney: false,
    Scheduling_Buyer: false,
    Scheduling_Seller: false,
    OtherContacts: 0,
  });

  // The following strings are the "base" strings that will be used to build the final string
  const people =
    '"{"+"`People`:"+"{"+' +
    Object.keys(master.People)
      .map((p) => (master.People[p] ? buildPeople(p, peopleSPLocation[p]) : ""))
      .filter(Boolean)
      .join("+") +
    '+"},"';

  const EarnestMoney =
    '"`EarnestMoney`:`" + {{Order.SalesContract.EarnestMoney.Deposits[1].Amount}}+"|"+{{Order.SalesContract.EarnestMoney.Deposits[2].Amount}}+"|"+{{Order.SalesContract.EarnestMoney.Deposits[3].Amount}}+"|"+{{Order.SalesContract.EarnestMoney.Deposits[4].Amount}}+"`,"';

  const OtherContacts =
    master.OtherContacts > 0
      ? '"`OtherContacts`:"+"{"+' +
        buildOtherContacts(master.OtherContacts || 0) +
        '+"}"+","'
      : "";

  const CustomValues =
    '"`CustomColl`:"+"{"+ "`CustomValA`:`"+ "PLACEHOLDER_FIELD_CODE" +"`,"+ "`CustomValB`:`"+ "PLACEHOLDER_FIELD_CODE" +"`,"+"`CustomValC`:`"+ "PLACEHOLDER_FIELD_CODE" +"`,"+ "`CustomValD`:`"+ "PLACEHOLDER_FIELD_CODE" +"`"+ "}"+ "}"';

  const collGroup = {
    OrgInfo: OrgInfo(master.OrgInfo_Buyer, master.OrgInfo_Seller),
    EarnestMoney,
    Scheduling: Scheduling(
      master.Scheduling_Buyer ? "Buyer" : "",
      master.Scheduling_Seller ? "Seller" : ""
    ),
    OtherContacts,
  };

  // The final string to copy into SoftPro:
  const finalStringObj = [
    people,
    ...Object.keys(collGroup)
      .filter((k) => master[k] || k.match(/orginfo|scheduling/gi))
      .map((k) => collGroup[k]),
    CustomValues,
  ]
    .map((str) => str.replace(/'+'##'+'/g, ""))
    .join("+")
    .replace(/\+{2,}/gi, "+");

  // "handle" functions for the checkboxes and number selectors
  const handleCheck = (e, p, people) => {
    const checked = e.target.checked;
    const loc = people ? "People" : "";
    let val = typeof checked === "boolean" ? checked : e.target.value; // could be in either location depending on the type of selector
    if (val.toString()?.match(/\d/gi)) val = parseInt(val);
    const newObj = master;
    if (people) newObj[loc][p] = val;
    else newObj[p] = val;
    setMaster({ ...newObj });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopy(true); // set copy to true to show the success message
    setTimeout(() => setCopy(false), 2000); // set copy back to false after 3 seconds
  };

  return (
    <div id="formulabuilder">
      <h1 className="sph1">SoftPro Formula Builder</h1>
      <table className="sptable">
        <tbody className="sptbody">
          <tr className="sptr">
            <td className="sptd" style={{ width: "20%" }}>
              <h2 style={{ marginTop: "0" }}>SoftPro Users:</h2>
              {Object.keys(master.People).map((p) => (
                <div key={p} className="option">
                  <label>
                    {p.replace(/([A-Z])/g, " $1")}
                    <Checkbox
                      p={p}
                      location={master.People[p]}
                      bool={true}
                      handleCheck={handleCheck}
                    />
                  </label>
                </div>
              ))}
              <h2>Other Info:</h2>
              {Object.keys(master).map((p) =>
                p !== "People" ? (
                  <div key={p} className="option">
                    {p.replace(/([A-Z])/g, " $1").replace(/_/g, " - ")}
                    {typeof master[p] !== "number" ? (
                      <Checkbox
                        p={p}
                        location={master[p]}
                        handleCheck={handleCheck}
                      />
                    ) : (
                      <NumSelector
                        key={p + "selector"}
                        num={master[p]}
                        outOf={p.match(/OtherContacts/gi) ? 11 : 4}
                        item={p}
                        handleCheck={handleCheck}
                      />
                    )}
                  </div>
                ) : null
              )}
            </td>
            <td className="sptd" height={"1px"}>
              <div style={{ textAlign: "center", height: "100%" }}>
                <textarea
                  className="sptextarea"
                  id="finalString"
                  value={finalStringObj}
                  readOnly={true}
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "2em" }}></div>
            </td>
            <td className="sptd" style={{ width: "10%" }}>
              <button
                className="spbutton"
                onClick={() => handleCopy(finalStringObj)}
              >
                <img src={clipboard} alt="clipboard" id="clipboard" />
              </button>
            </td>
          </tr>
          <tr className="sptr">
            <td className="sptd" colSpan={3}>
              {copy ? <CopySuccess /> : <></>}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SPFormulaBuilder;

export const buildOtherContacts = (num) => {
  const buildPerson = (num, contact) =>
    '"`Person' +
    num +
    '`:"+"{"+ "`Name`:`"+' +
    contact +
    ".People[" +
    num +
    '].Name}}+"`,"+ "`Title`:`"+' +
    contact +
    ".People[" +
    num +
    '].Title}}+"`,"+ "`Email`:`"+' +
    contact +
    ".People[" +
    num +
    '].Email}}+"`,"+ "`Phone`:`"+' +
    contact +
    ".People[" +
    num +
    '].Phone}}+"`,"+ "`Cell`:`"+' +
    contact +
    ".People[" +
    num +
    '].Cell}}+"`"+ "}"+","+';
  return num
    ? [...Array(parseInt(num)).keys()]
        .map((i) => {
          i += 1;
          const contact = `{{Order.Others[${i}]`;
          return (
            '"`Contact' +
            i +
            '`:"+"{"+ "`Type`:`"+' +
            contact +
            '.ContactTypeDescription}}+"`,"+ "`Name`:`"+' +
            contact +
            '.Name}}+"`,"+ "`Email`:`"+' +
            contact +
            '.Email}}+"`,"+ "`Phone`:`"+' +
            contact +
            '.Phone}}+"`,"+' +
            buildPerson("1", contact) +
            buildPerson("2", contact) +
            ' "}"+","'
          );
        })
        .join("+")
    : "";
};

export const CopySuccess = () => {
  return (
    <div className="successmsg">
      <div className="successbox">
        <span>Copied to clipboard!</span>
      </div>
    </div>
  );
};

export const NumSelector = (props) => {
  const { num, outOf, item, handleCheck } = props;
  return (
    <select
      className="spselect"
      value={num}
      key={item}
      onChange={(e) => handleCheck(e, item)}
      style={{ width: "50px" }}
    >
      {[...Array(outOf).keys()].map((num) => (
        <option key={num} value={num}>
          {num}
        </option>
      ))}
    </select>
  );
};

export const Checkbox = (props) => {
  const { p, location, bool, handleCheck } = props;
  return (
    <input
      className="spinput"
      type="checkbox"
      checked={location}
      onChange={(e) => handleCheck(e, p, bool)}
    />
  );
};

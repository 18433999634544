import React, { Component } from 'react';
import JSONTree from 'react-json-tree';
import copy from 'copy-text-to-clipboard';
import { jsonTreeConfig } from '../shared/jsonTreeConfig'
import "./order.css";

var config = require('../../config/config');
const returnJsonWithAuth = require('../shared/authHandler');

class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mapping: {},
            url: "test",
            linkTitle: "Loading",
            companyId: this.props.match.params["companyId"],
            data: {},
            companyName: props.location.state,
            orderId: this.props.match.params["orderId"],
            orderExists: true,
            s3LinkTitle: "Steps completed (S3 Bucket)",
            s3Url: config.orderBucketUrl + this.props.match.params["companyId"] + '/' + this.props.match.params["orderId"] + "/"
        };
        if (!this.state.companyName) {
            this.getCompanyName();
        }
        this.getTpWebDiagnostic = this.getTpWebDiagnostic.bind(this);
        this.getMappedData = this.getMappedData.bind(this);
    }

    componentDidMount() {
        this.getMappedData();
        this.getTpWebDiagnostic();
    }

    getCompanyName() {
        fetch(config.diagnosticApiHost + "company/" + this.state.companyId, config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, this.getCompanyName))
            .then(
                (result) => {
                    if (result) {
                        this.setState({
                            companyName: result.Name
                        });
                    }
                },
                (error) => {
                    this.setState({
                        url: "",
                        linkTitle: "Error",
                    });
                }
            );
    }

    getTpWebDiagnostic() {
        this.setState({
            linkTitle: "Loading"
        })
        fetch(config.diagnosticApiHost + "tpWebDiagnostic/" + this.state.companyId + "?orderId=" + this.state.orderId, config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, this.getTpWebDiagnostic))
            .then(
                (result) => {
                    if (result) {
                        if (result.createResponse === "\"Success\"") {
                            this.getTpWebDiagnostic();
                            return;
                        }
                        this.setState({
                            url: result.url,
                            linkTitle: "Launch CloseSimple2 UI in new Tab/Window",
                        });
                    }
                },
                (error) => {
                    debugger;
                    this.setState({
                        url: "",
                        linkTitle: "Error",
                    });
                }
            );
    }

    getMappedData() {
        fetch(config.diagnosticApiHost + "mappingDiagnostic/" + this.state.companyId + "?orderId=" + this.state.orderId, config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, this.getMappedData))
            .then(
                (result) => {
                    if (result.MappedData) {
                        this.setState({
                            mapping: result.MappedData.map((elem) => {
                                let obj = {
                                    CSFieldName: elem.CSFieldName,
                                    DisplayName: elem.DisplayName,
                                    SourceFieldName: elem.SourceFieldName,
                                    MappedValue: elem.mappedValue
                                }
                                if (elem.error) {
                                    obj.Error = elem.error;
                                }
                                return obj;
                            }),
                            data: result.OrderData
                        });
                    }
                    else {
                        this.setState({
                            orderExists: false
                        });
                    }
                },
                (error) => {
                    debugger;
                }
            );
    }

    getCompanyRoute = (companyId, companyName) => {
        this.props.history.push({
            pathname: '/company/' + companyId,
            state: {
                Id: companyId,
                Name: companyName
            }
        });
    }


    copyToClipboard = (data) => {
        copy(JSON.stringify(data));
    }

    toOrderEdit = () => {
        var companyId = this.state.companyId;
        var orderId = this.state.orderId;
        var companyName = this.state.companyName;
        this.props.history.push({
            pathname: '/company/' + companyId + '/orderEdit/' + orderId,
            state: {
                companyName
            }
        });
    }

 
    render = () => {

        return (
            <div>
                <a href="/">Home</a><br />
                <div hidden={this.state.orderExists}>Order Does not Exist</div>
                <div hidden={!this.state.orderExists}>
                    <div className="link" onClick={() => this.getCompanyRoute(this.state.companyId, this.state.companyName)}>{this.state.companyName}</div>
                    <a href={this.state.s3Url} target="_blank" rel="noopener noreferrer">{this.state.s3LinkTitle}</a>
                    <hr />
                    <button onClick={() => this.getTpWebDiagnostic()}>Refresh Link</button><br />
                    <a href={this.state.url} target="_blank" rel="noopener noreferrer">{this.state.linkTitle}</a>
                    <hr />
                    <span>Mapped Data</span><br />
                    <button onClick={() => this.copyToClipboard(this.state.mapping)}>Copy To Clipboard</button>
                    <JSONTree data={this.state.mapping} {...jsonTreeConfig} />
                    <hr />
                    <span>Original Order Data</span><br />
                    <button onClick={() => this.copyToClipboard(this.state.data)}>Copy To Clipboard</button>  <button onClick={() => this.toOrderEdit()}>Edit</button>
                    <JSONTree data={this.state.data} {...jsonTreeConfig} />
                </div>
            </div>
        );
    }
}

export default Order;
import React, { Component } from 'react';

var config = require('../../config/config');
const returnJsonWithAuth = require('../shared/authHandler');

class DocumentRegex extends Component {

  constructor(props) {
    super(props);
    this.state = props.data;
    this.state.data = [];
    this.regexResults = this.regexResults.bind(this);
  }

  componentDidMount() {
    this.regexResults();
  }

  regexResults() {
    fetch(config.diagnosticApiHost + "company/" + this.state.companyId + "/order/" + this.state.orderId + "/documentRegex?stepId=" + this.state.stepId, config.apiOptions("GET"))
      .then(res => returnJsonWithAuth(res, this.state, this.getMappedData))
      .then(
        (result) => {
          this.setState({
            data: result
          });
        },
        (error) => {
          debugger;
        }
      );
  }

  render = () => {
    const { data } = this.state;
    return (
      <div>
        <span>Document Regex Results</span><br />
        <table>
          <thead>
            <tr>
              <td>Communication Name</td><td>Step Input Field Name</td><td>Regex</td><td>Matched Documents</td>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item.CommunicationName + item.StepInputFieldName + item.ValidateRegex}>
                <td>{item.CommunicationName}</td><td>{item.StepInputFieldName}</td><td>{item.ValidateRegex}</td><td>{item.MatchedDocuments.length === 0 ? 'None' : item.MatchedDocuments.join('\n')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default DocumentRegex;
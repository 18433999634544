export const peopleSPLocation = {
  Closer: "Escrow.Officer",
  Processor: "Escrow.PreCloserEscrowAssistant",
  TitleOfficer: "Title.Officer",
  HeldBy: "FileHeld.HeldBy",
  OrderReceivedBy: "ReceivedBy",
  OrderCompletedBy: "CompletedBy",
  OrderReservedBy: "ReservedBy",
  EscrowOpenedBy: "Escrow.OpenedBy",
  TitleOpenedBy: "Title.OpenedBy",
};

export const buildPeople = (csName, who) =>
  '"`' +
  csName +
  '`:"+ "{"+ "`Name`:`"+ {{Order.' +
  who +
  '.FullName}}+"`,"+ "`Title`:`"+ GetSecurityUserString({{Order.' +
  who +
  '}}, "Title")+"`,"+ "`Email`:`"+ GetSecurityUserString({{Order.' +
  who +
  '}}, "EmailAddress")+"`,"+ "`Phone`:`"+ GetSecurityUserString({{Order.' +
  who +
  '}}, "PhoneNumber")+"`,"+ "`Cell`:`"+ GetSecurityUserString({{Order.' +
  who +
  '}}, "MobileNumber")+"`,"+ "`License`:`"+ GetSecurityUserString({{Order.' +
  who +
  '}}, "LicenseNumber")+"`"+ "}"+","';

// export { buildPeople, peopleSPLocation };

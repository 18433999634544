import React, { Component } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import copy from 'copy-text-to-clipboard';
import "./orderEdit.css";

var config = require('../../config/config');
const returnJsonWithAuth = require('../shared/authHandler');

class OrderEdit extends Component {

    constructor(props) {
        super(props);
        var nameToUse = "default"
        if (props.location.state.companyName) {
            nameToUse = props.location.state.companyName;
        }
        this.state = {
            mapping: {},
            url: "test",
            linkTitle: "Loading",
            companyId: this.props.match.params["companyId"],
            orderId: this.props.match.params["orderId"],
            companyName: nameToUse,
            data: {}
        };
        this.getRawData = this.getRawData.bind(this);
    }

    componentDidMount() {
        this.getRawData();

    }

    getRawData() {
        fetch(config.diagnosticApiHost + "rawData/" + this.state.companyId + "?orderId=" + this.state.orderId, config.apiOptions("GET"))
            .then(res => returnJsonWithAuth(res, this.state, this.getRawData))
            .then(
                (result) => {
                    if (result) {
                        this.setState({
                            data: JSON.parse(result.OrderData)
                        });
                    }
                },
                (error) => {
                    debugger;
                }
            );
    }

    getCompanyRoute = (companyId, companyName) => {
        this.props.history.push({
            pathname: '/company/' + companyId,
            state: {
                Id: companyId,
                Name: companyName
            }
        });
    }


    copyToClipboard = (data) => {
        copy(JSON.stringify(data));
    }

    saveData = () => {
        fetch(config.diagnosticApiHost + "rawData/" + this.state.companyId + "?orderId=" + this.state.orderId, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'AnyVal'
            },
            credentials: "include",
            body: JSON.stringify(this.state.data)
        }).then(res => returnJsonWithAuth(res, this.state, this.saveData));
    }

    onJsonInputChange = (data) => {
        if (data.jsObject) {
            this.setState({
                data: data.jsObject
            });
        }
    }
    render = () => {
        return (
            <div>
                <a href="/">Home</a><br />
                <div className="link" onClick={() => this.getCompanyRoute(this.state.companyId, this.state.companyName)}>{this.state.companyName}</div>
                <hr />
                <span>Original Order Data</span>
                <br />
                <button onClick={() => this.copyToClipboard(this.state.data)}>Copy To Clipboard</button>   <button onClick={() => this.saveData()}>Save</button><br />
                <div>Note: If a large amount of editing and validation are required, it is best to copy and paste into a better editor.</div>
                <JSONInput placeholder={this.state.data} locale={locale} onChange={this.onJsonInputChange} />
            </div>
        );
    }
}

export default OrderEdit;
//let environmentName = "prod";
let config = {
    orderBucketUrl: "https://s3.console.aws.amazon.com/s3/buckets/cs2-prod-us-east-1-order-data-bucket/OrderData/",
    diagnosticApiHost: "https://api.diagnostics.closesimple.com/",
    adminAuthApiHost: "https://api.auth.admin.closesimple.com/",
    adminAuthHost: "https://auth.admin.closesimple.com/",
    cognitoClientId: "3h3jsm5jesu6vcmeaa84qjoem1"
};

config.tokenUrl = config.adminAuthHost + "authorize?response_type=code&client_id=" + config.cognitoClientId + "&redirect_uri=" + window.location.origin + "&scope=openid+profile+aws.cognito.signin.user.admin&idp_identifier=closesimple.com&state=";

config.apiOptions = (method, body) => {
    var options = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'AnyVal'
        },
        credentials: "include"
    };
    if (body) {
        options.body = body;
    }
    return options;
};

module.exports = config;

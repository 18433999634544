import React, { Component } from 'react';

var config = require('../../config/config');
const returnJsonWithAuth = require('../shared/authHandler');

class CreateCompanyDialog extends Component {

  constructor(props) {
    super();

    this.state = {
      toggleModalFunction: props.toggleCompanyDialog,
      tpsTypes: [],
      companyId: "",
      selectedTps: ""
    };

    this.cancel = this.cancel.bind(this);
    this.createCompany = this.createCompany.bind(this);
    this.getTpsTypes = this.getTpsTypes.bind(this);
  }

  componentDidMount() {
    this.getTpsTypes();
  }

  getTpsTypes() {
    fetch(config.diagnosticApiHost + "tpsTypes", config.apiOptions("GET"))
      .then(res => returnJsonWithAuth(res, this.state, this.getTpsTypes))
      .then(
        (result) => {
          this.setState({
            tpsTypes: result,
            selectedTps: result[0]
          });

        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        })
  }

  cancel() {
    this.state.toggleModalFunction();
  }

  createCompany() {
    fetch(config.diagnosticApiHost + "newCompany", config.apiOptions("POST", JSON.stringify({
      companyId: this.state.companyId,
      tpsType: this.state.selectedTps
    })))
      .then(() => {
        this.state.toggleModalFunction();
      },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        })
  }

  companyIdChanged = (event) => {
    this.setState({
      companyId: event.target.value
    });
  }

  tpsChanged = (event) => {
    this.setState({
      selectedTps: event.target.value
    });
  }

  render() {
    return (
      <div>
        <h2 ref={subtitle => this.subtitle = subtitle}>Create Company</h2>
        <div>
          Company Id:
          <br />
          <input value={this.state.companyId} onChange={this.companyIdChanged} />
        </div>
        <div>Select TPS:</div>
        <select onChange={this.tpsChanged}>
          {this.state.tpsTypes.map(item => (
            <option value={item}>{item}</option>
          ))}
        </select>
        <br />
        <button onClick={this.cancel}>Cancel</button>  <button onClick={this.createCompany}>Create</button>
      </div>
    );
  }
}

export default CreateCompanyDialog;

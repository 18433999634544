const OrgInfo = (Buyer, Seller) => {
  const build = (side, numofContacts) => {
    return (
      '"`' +
      side +
      '`:"+"["+' +
      [...Array(parseInt(numofContacts)).keys()]
        .map((i) => {
          i += 1;
          return (
            '"{"+ "`Name`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].Name}}+"`,"+ "`Email`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].Email}}+"`,"+ "`Phone`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].Phone}}+"`,"+ "`People`:"+"["+' +
            '"{"+ "`FirstName`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].People[1].FirstName}}+"`,"+ "`LastName`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].People[1].LastName}}+"`,"+ "`Email`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].People[1].Email}}+"`,"+ "`Phone`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].People[1].Cell}}+"`"+ "},"+' +
            '"{"+ "`FirstName`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].People[2].FirstName}}+"`,"+ "`LastName`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].People[2].LastName}}+"`,"+ "`Email`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].People[2].Email}}+"`,"+ "`Phone`:`"+{{Order.' +
            side +
            "[" +
            i +
            '].People[2].Cell}}+"`"+ "}"+' +
            '"]"+"},"'
          );
        })
        .join("+") +
      '+"]"+' +
      '""+","'
    );
  };

  return (
    '"`OrgInfo`:"+"{"+' +
    (Buyer ? build("Buyers", Buyer) : "") +
    (Seller ? "+" + build("Sellers", Seller) : "") +
    '+"}"+","+'
  );
};

export default OrgInfo;
